.user {
    display: grid;
    align-items: center;
    grid-template-columns: 4rem 1fr auto;
    cursor: pointer;
}

.username {
    color: var(--dark);
    font-size: 1rem;
    font-weight: 500;
}

.totalActivityHour {
    color: var(--dark);
    font-size: 0.7rem;
}

.badge {
    background: var(--red-transparent);
    color: var(--red);
    fill: var(--red);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 0.5rem;
    height: 2rem;
    font-size: 0.75rem;
    padding: 0 0.5rem;
    font-weight: 600;
}

.badge > svg {
    width: 0.75rem;
    height: 0.75rem;
}

.links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.primary-links {
    margin-top: 1.5rem;
}

.links > * + * {
    margin-top: 1rem;
}

.link {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.link:hover {
    opacity: 0.7
}

.link:focus {
    color: var(--orange);
}

.primary-link {
    color: var(--dark);
}

.danger-link {
    color: var(--red);
}

.link svg {
    fill: currentColor;
}

.link span {
    position: relative;
    margin-left: 0.5rem;
    font-weight: 500;
}

.link.active {
    color: var(--red);
}

.link.active > *:nth-child(3) {
    position: absolute;
    right: 0;
}
