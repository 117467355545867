@import '../../../styles/viewports.css';

.home {
    max-width: 89.75rem;
    width: 100%;
    margin: 0 auto 1rem auto;
    padding: 1rem 0;
}

.input-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 56.25rem;
    margin: 0.5rem auto 1.5rem auto;
    padding: 0 1rem;
}

.error-box {
    width: 100%;
    max-width: 56.25rem;
    margin: 0 auto;
}

.swiper-header {
    margin: 0 0 1rem 1rem;
    display: flex;
    align-items: center;
}

.swiper-header > * + * {
    margin-left: 0.5rem;
}

.next-prev-buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    user-select: none;
}

.next-prev-buttons > * {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--pink-transparent);
    fill: var(--pink);
    cursor: pointer;
    opacity: 0.8;
}

.next-prev-buttons > * + * {
    margin-left: 1rem
}

.footer {
    padding-top: 1rem;
    border-top: 1px solid var(--border-color);
    background-color: var(--white);
    margin: auto 0 1rem 0;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-bottom: 4.5rem
}

.footer > *:nth-child(1) {
    max-width: 20rem;
    width: calc(100% - 2rem);
    margin: 1rem;
}

.footer > * + * {
    display: grid;
    align-items: center;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 0.5rem;
    color: var(--dark);
    font-weight: 500;
    text-align: center;
    margin: 0 auto;
    cursor: pointer;
}

.footer > * + * > *:hover {
    color: var(--gray-5)
}

.gap {
    min-width: 1rem;
    height: 100%;
    display: flex;
    align-items: center;
}

.carouselWrapper {
    margin: 0 1rem;
    max-width: 56.25rem;
    padding-bottom: 2rem;
    position: relative;
}

.carousel > *:nth-child(1) > * {
    width: 100%;
}

.carouselImage {
    overflow: hidden;
    width: 100%;
    border-radius: 1rem;
    height: auto;
    margin-right: 1rem;
}

.carouselImage > span > img {
    overflow: hidden;
    border-radius: 1rem;
    width: 100%;
    height: 100%;
}

.carouselImage.disabled {
    pointer-events: none;
}

@media (--lg) {
    .carouselWrapper {
        margin: 0 auto
    }

    .swiper-header {
        margin: 0 0 1rem 0;
    }

    .input-wrapper {
        padding: 0;
    }

    .home {
        margin: 0 auto;
        padding: 1rem;
    }

    .footer {
        width: 100%;
        max-width: 87.75rem;
        background-color: unset;
        border-top: 0;
        display: flex;
        flex-direction: row;
        margin: 0 auto;
        padding-bottom: 0
    }

    .footer > * + * {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        grid-template-rows: unset;
        gap: unset;
        margin: 0 0 0 auto;
    }

    .footer > * + * > * {
        margin-left: 1rem
    }
}
