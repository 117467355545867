.button {
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.5rem;
    border: 0;
    border-radius: 0.5rem;
    appearance: none;
    outline: none;
    cursor: pointer;
}

.button.inactive {
    background: rgba(0, 0, 0, 0.3);
}

.button.active {
    background-color: var(--red-transparent-3);
}

.iconWrapper svg {
    width: 100%;
    height: auto;
    fill: var(--white);
}
