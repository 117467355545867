@import '../../../styles/viewports.css';

.moduleInspector {
    display: flex;
    justify-content: center;
    height: calc(100vh - 5.85rem);
}

.module {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--white);
    padding: 0 0 2rem 0;
    border-radius: 0.5rem;
    width: 100%;
}

.subText {
    margin-top: 1rem;
}

.caloryIconInspector {
    width: 18.4375rem;
    height: 6.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(227, 53, 42, 0.1);
    border-radius: 0.5rem;
    margin: 0.8125rem 0 1rem 0;
}

.button {
    margin: 1rem 0.8rem 0 0.8rem;
}

@media (--lg) {
    .moduleInspector {
        height: 100%;
    }
}
