.channels {
    margin-top: 2rem;
    height: 14.75rem;
    overflow: auto;
}

.channels::-webkit-scrollbar {
    width: 0.5rem;
    background-color: var(--gray-6);
    border-radius: 1rem;
}

.channels::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: var(--gray-5);
}

.trainers {
    margin-top: 1rem;
}

.trainersWrapper {
    position: relative;
}

.trainerInputWrapper {
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white);
    border-radius: 0.5rem;
    border: 1px solid var(--border-color);
    outline: 0 !important;
    overflow: hidden;
}

.trainerInputWrapper.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
}

.selectedTrainerWrapper {
    width: 100%;
    display: flex;
    cursor: pointer;
}

.selectedTrainer {
    height: 2rem;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    color: var(--dark);
    font-size: 1rem;
    font-weight: 500;
    border-radius: 1rem;
}

.selectedTrainer > div {
    border-radius: 50%;
    margin-right: 0.5rem;
}

.selectedTrainer svg {
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
    fill: var(--red);
    cursor: pointer;
}

.trainerInput {
    width: 100%;
    height: 4rem;
    padding: 0 1rem;
    color: var(--dark);
    font-size: 1rem;
    font-weight: 500;
    background-color: transparent;
    border: 0;
    outline: 0 !important;
}

.trainerInput::placeholder {
    color: var(--gray-5);
}

.trainerInputIcon {
    fill: var(--gray-5);
    margin-right: 1rem;
    cursor: pointer;
}

.trainerList {
    position: absolute;
    left: 0;
    width: 100%;
    outline: 0 !important;
    background-color: var(--white);
    border-radius: 0 0 1rem 1rem;
    border: 1px solid var(--border-color);
    z-index: 1;
    cursor: pointer;
}

.trainerListScroller {
    max-height: 9rem;
    margin: 0 1rem 0 0;
    overflow-y: auto;
}

.trainerListScroller::-webkit-scrollbar {
    width: 0.5rem;
    background-color: var(--gray-6);
    border-radius: 1rem;
}

.trainerListScroller::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: var(--gray-5);
}

.trainerListItem {
    height: 3rem;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    margin-right: 1rem;
}

@media (hover: hover) {
    .trainerListItem:hover {
        background-color: var(--gray-4);
    }
}

.trainerListItem:not(:first-child) {
    border-top: 1px solid var(--border-color);
}

.trainerListItem > div:first-child {
    margin-right: 0.5rem;
}

.actions {
    padding-top: 1.5rem;
    margin-top: auto;
}
