@import '../../../styles/viewports.css';

.wrapper {
    width: 100%;
    max-width: 44.25rem;
    padding-top: 10rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.background {
    width: 100%;
    height: 8.5rem;
    position: relative;
    display: flex;
    justify-content: center;
    background: var(--white);
    border-radius: 1rem;
    box-shadow: var(--shadow-1);
}

.background > * {
    position: absolute;
    bottom: 0;
    max-width: 100%;
    height: 18.5rem;
    z-index: 2;
}

.wrapper > :not(:nth-child(2n+1)) {
    margin-top: 1rem
}

.wrapper > :nth-child(3) {
    margin-top: 1rem;
    white-space: pre-line;
}

.wrapper > :nth-child(4) {
    width: 9.5rem;
}

@media (--lg) {
    .wrapper > :not(:nth-child(2n+1)) {
        margin-top: 3.5rem
    }
}
