@import "../../../styles/variables.css";

.broadcastStatusBadge {
    color: #FFF;
    font-family: var(--base-font-family);
    font-size: 0.625rem;
    padding: .1875rem .5rem;
    border-radius: 1rem;
    text-align: center;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    transition: padding ease-in-out 200ms, font-size ease-in-out 200ms, background ease-in-out 200ms;
}

.sizeBig {
    padding: .3125rem .5rem;
    font-size: 0.75rem;
}

.live {
    background: var(--red);
}

.past {
    background: var(--yellow);
}

.future {
    background: var(--green);
}
