.card {
    width: 13.75rem;
    height: 12.75rem;
    margin-right: 1rem;
    position: relative;
    border-radius: 0.25rem;
    overflow: hidden;
    cursor: pointer;
    background: var(--white);
    padding: 0.75rem;
}

.card > span {
    width: 100%
}

.card > span > img {
    width: 100% !important;
    height: 7.75rem !important;
    object-fit: cover;
    object-position: center;
    border-radius: 0.25rem;
}

.backdrop.homePage {
    display: none;
}

.backdrop {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background-color: var(--backdrop);
    transition: 0.5s ease all;
    z-index: 1;
    cursor: pointer;
}

.backdrop:hover {
    transition: 1s ease all;
    background-color: var(--backdrop-hover);
}

.live-badge {
    position: absolute;
    left: 1.25rem;
    bottom: 4.75rem;
    padding: 0.312rem 0.5rem;
    background-color: var(--red);
    border-radius: 1.625rem;
    z-index: 2;
    font-size: 0.625rem;
}

.live-badge.coming-soon {
    background-color: var(--dark-green);
}

.live-badge.past-broadcast {
    background-color: var(--yellow);
}

.name {
    position: absolute;
    left: 0.75rem;
    bottom: 2.5rem;
    z-index: 2;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.title {
    position: absolute;
    left: 0.75rem;
    bottom: 0.75rem;
    z-index: 2;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.statusIndicator {
    position: absolute;
    left: 1rem;
    top: 1rem;
    z-index: 2;
}

.cardInBroadcastFixture {
    height: 8.5rem;
    position: relative;
    border-radius: .5rem;
    overflow: hidden;
}

.cardInBroadcastFixture > span {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.cardInBroadcastFixtureTitle {
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    z-index: 2;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: var(--base-font-family);
    font-size: 1.125rem;
    font-weight: bold;
    color: var(--white);
}

.cardInBroadcastFixtureName {
    position: absolute;
    left: 1rem;
    bottom: 2.5rem;
    z-index: 2;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: .875rem;
    color: var(--white);
    letter-spacing: -0.01px;
}

.playButton {
    position: absolute !important;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 2;
}

.playButton.homePage {
    bottom: 3.5rem;
}
