@import '../../../styles/viewports.css';

.wrapper {
    width: 100%;
    padding-bottom: 1rem;
    text-align: center;
    padding-top: 1rem;
}

.image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
}

.image span, .image img {
    position: relative;
    width: auto;
    height: 10rem;
}

.imagePlaceholder {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 5rem;
    background-color: var(--gray-7);
    border-radius: 0.5rem;
}

@media (--lg) {
    .imagePlaceholder {
        border-radius: 1rem 1rem 0 0;
        height: 9rem;
    }

    .content {
        padding: 0 1rem;
    }

    .image span, .image img {
        width: auto;
        height: 19rem;
    }
}
