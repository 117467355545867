.wrapper {
    position: relative;
    width: 100%;
    background-color: var(--white);
    border-radius: 1rem;
}

.heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 1.5rem 1rem;
}

.heading > span > img{
    width: 4rem!important;
    height: 4rem!important;
    border-radius: 0.5rem;
}

.title {
    margin: 0 1.5rem 0.5rem;
}

.body {
    padding: 1rem 0;
    margin: 0 1.5rem;
    border-top: 1px solid var(--gray-6);
}
