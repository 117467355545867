@import '../../../styles/viewports.css';

.modal {
    flex: 1;
}

.overlay {
    position: fixed;
    left: 0;
    top: 0;
    z-index: var(--z-index-5);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow-y: auto;
}

.overlay.transparent {
    background-color: transparent !important;
}

.overlay.transparent > .wrapper {
    background-color: transparent !important;
}

.overlay.centered > .wrapper {
    max-width: 29rem;
    height: auto;
    min-height: initial;
    border-radius: 1rem;
    background-color: var(--white);
}

.overlay.actionSheet {
    position: fixed;
    left: 0;
    right: 0;
    top: unset;
    bottom: 0;
    z-index: var(--z-index-5);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.overlay.actionSheet > .wrapper {
    width: 100%;
    max-width: unset;
    height: auto;
    min-height: initial;
    border-radius: 1rem 1rem 0 0;
    background-color: var(--white);
    margin: auto 0 0 0;
    padding: 0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.overlay.fullscreen > .wrapper {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.backdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--gray-3);
}

.overlay.fullscreen > .backdrop {
    background-color: var(--light)
}

.wrapper {
    position: relative;
    width: 100%;
    min-height: 100%;
    display: flex;
    margin: auto 0;
    background-color: var(--light);
}

@media (--lg) {
    .overlay {
        padding: 1rem;
    }

    .overlay.fullscreen {
        padding: 0;
    }

    .overlay.fullscreen > .backdrop {
        background-color: var(--gray-3);
    }

    .sizeFullscreen .wrapper {
        width: 100vw;
        height: 100vh;
        max-width: 100%;
        border-radius: 0;
    }

    .large .wrapper {
        max-width: 46rem !important;
    }

    .medium .wrapper {
        max-width: 36rem !important;
    }

    .small .wrapper {
        max-width: 29rem !important;
    }

    .wrapper {
        max-width: 29rem;
        height: auto;
        min-height: initial;
        border-radius: 1rem;
        background-color: var(--white);
    }

    .overlay.actionSheet > .wrapper {
        margin: auto 0;
    }

    .overlay.actionSheet > .wrapper {
        border-radius: 1rem;
    }
}
