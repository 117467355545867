@import '../../../styles/viewports.css';

.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.logo {
    margin: 1rem auto
}

.wrapper {
    margin: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.wrapper > span > img {
    border-radius: 1rem 1rem 0 0;
    height: 100%;
    overflow: hidden;
}

.contentWrapper {
    margin: -5.5rem 1rem 5.5rem;
    position: relative;
    height: 17.75rem;
}

.content {
    position: absolute;
    left: 0;
    right: 0;
    padding: 1.5rem 1rem;
    background: var(--white);
    border-radius: 1rem;
    overflow-y: auto;
    height: 100%;
}

.content::-webkit-scrollbar {
    width: 0.5rem;
    background-color: var(--gray-6);
    border-radius: 1rem;
}

.content::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: var(--gray-5);
}

.buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto 1rem 1.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
}

.lineContentWrapper > *:not(:last-child) {
    border-bottom: 1px solid var(--border-color);
}

.lineContent {
    display: grid;
    padding: 0.5rem 0;
    grid-template-columns: 1.5rem 1fr;
    gap: 0.5rem;
    align-items: center;
}

.lineContent > svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--red);
}

@media (--lg) {
    .container {
        padding: 1rem;
    }

    .logo {
        display: none;
    }

    .card {
        background: var(--white);
        border-radius: 1rem;
        padding: 4.5rem 1rem 1rem;
    }

    .wrapper {
        overflow: unset;
    }

    .wrapper > span > img {
        width: 17.5rem !important;
        height: 10.75rem !important;
        margin-bottom: -4.5rem;
        border-radius: 1rem;
        z-index: 5;
    }

    .content {
        margin: 1.5rem 3rem;
        padding: 0;
        border-radius: 0;
        max-height: 18.75rem;
        position: relative;
    }

    .buttons {
        position: relative;
        margin: 0;
    }

    .contentWrapper {
        margin: 0;
        position: relative;
        height: 17.75rem;
    }
}
