@import '../../../styles/viewports.css';

.wrapper {
    margin-bottom: 9.5rem;
    overflow: hidden;
}

.actions {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 1rem 1rem 1rem;
}

.actions > * + * {
    margin-top: 0.5rem;
}

@media (--lg) {
    .container {
        position: relative;
        height: 100%;
        padding-top: 13rem;
    }

    .wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: var(--z-index-1);
    }

    .actions {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--white);
        border-radius: 1rem;
        padding-top: 16rem;
    }

    .actions > * + * {
        margin-top: 0;
        margin-left: 0.5rem;
    }
}
