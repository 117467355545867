.card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.5rem;
    border-bottom: 1px solid var(--border-color);
    padding: 0 1rem;
}

.card.lastItem {
    border-bottom: 0;
}
