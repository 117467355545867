@import '../../../styles/viewports.css';

.broadcastFixtureScreenWrapper {
    width: 100%;
    max-width: 89rem;
    margin: 0 auto;
    padding-bottom: 5rem;
}

.broadcastFixtureScreen {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.divider {
    flex: 0 0 1rem;
}

@media (--md) {
    .broadcastFixtureScreen {
        flex-direction: row;
        justify-content: center;
        padding-top: 1.5rem;
    }
}

@media (--lg) {
    .broadcastFixtureScreen {
        padding-bottom: 2rem;
    }
}
