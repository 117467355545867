.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.labels {
    margin: 2rem 1.5rem 1.5rem;
}

.select-button {
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 1px dashed var(--gray-4);
    border-radius: 0.5rem;
    outline: none !important;
}

.select-button svg {
    fill: var(--red);
    margin-right: 1rem;
}

.select-button-label {
    margin-top: 2px;
    color: var(--gray-4);
    font-weight: 500;
}

.hidden {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    display: none;
}

.selected-image {
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    background: var(--gray-5);
    border-radius: 0.5rem;
}

.selected-image-label {
    margin-left: 1rem;
    color: var(--dark);
    font-weight: 500;
}

.selected-image-remove-button {
    padding: 0;
    margin-left: auto;
    color: var(--red);
    border: 0;
    cursor: pointer;
    outline: 0 !important;
    background: transparent;
}

.selected-image-remove-button svg {
    fill: currentColor;
}

.modal {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 0.5rem;
    padding: 1rem;
}

.modal-content {
    width: 100%;
    padding: 1rem;
}

.crop-container {
    position: relative;
    width: 100%;
    height: 26rem;
    border-radius: 1rem;
    overflow: hidden;
}

.modal-actions {
    width: 100%;
    margin-top: 1rem;
}

.modal-actions > * + * {
    margin-top: 0.5rem;
}
