.video {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
}

.videoCloseButton {
    position: absolute;
    width: 35px;
    height: 35px;
    right: 0.15rem;
    top: 0.15rem;
    z-index: 3;
    background: var(--white);
    fill: var(--dark);
    border-radius: 17.5px;
    cursor: pointer;
}

.videoCloseButton:hover{
    fill: var(--gray-2);
}

.videoTitle{
    margin-bottom: 0.5rem;
}
