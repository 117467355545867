.contentWrapper {
    margin-bottom: 1rem;
    max-height: calc(100vh - 16rem);
    overflow: auto;
    padding-right: 1.5rem;
    white-space: pre-wrap;
}

.contentWrapper::-webkit-scrollbar {
    width: 0.5rem;
    background-color: var(--gray-6);
    border-radius: 1rem;
}

.contentWrapper::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: var(--gray-5);
}

.modal > button {
    margin: 0 auto
}

.modal {
    padding: 1.5rem;
}
