@import '../../../styles/viewports.css';

.listWrapper {
    flex: 1;
    overflow: auto;
}

.listLoader {
    padding: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.broadcastsGrid {
    display: grid;
    grid-gap: .75rem;
    grid-template-columns: repeat(1, 1fr);
}

.broadcastsGrid > * {
    width: 100%;
    height: auto;
}

@media (--xs) {
    .broadcastsGrid {
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
    }

    .broadcastsGrid > * {
        max-height: 14.275rem;
    }

}

@media (--md) {
    .broadcastsGrid {
        grid-template-columns: repeat(auto-fill, 13.75rem);
    }

    .broadcastsGrid > * {
        max-height: 9rem;
    }
}

@media (--lg) {
    .broadcastsGrid > * {
        max-height: 9rem;
    }
}
