@import '../../../styles/viewports.css';

.wrapper {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.wrapper .controls {
    padding: 1rem 0;
    background: var(--white);
    border-top: 1px solid var(--gray-6);
    z-index: var(--z-index-2);
}

.wrapper .controlButton {
    width: 6rem;
    height: 6rem;
    opacity: 0.7;
    border-radius: 1rem;
    overflow: hidden;
    margin: 0 0.5rem !important;
    position: relative;
}

.wrapper .controlButton > *:nth-child(1) {
    object-fit: cover;
}

.wrapper .controlButton > *:nth-child(2) {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    fill: var(--green);
}

.wrapper .controlButton.active {
    color: var(--white);
    border: 2px solid var(--red);
    opacity: 1;
}

.wrapper .items {
    margin: 1rem;
    padding-bottom: 5rem;
}

.wrapper .items > * > * {
    margin-top: 0.5rem;
}

.videoArea {
    width: 100%;
    height: 100%;
    padding: 1rem;
    background: var(--white);
    border-radius: 0.5rem;
}

.videoWrapper iframe > * {
    width: 100% !important;
    overflow: hidden;
    max-width: 100% !important;
    height: 100%;
    flex: 1;
}

.card-buttons {
    margin-top: 1.5rem;
}

.text-center {
    text-align: center;
}

@media (--lg) {
    .wideWrapper {
        max-width: 89.75rem;
        width: 100%;
        margin: 1.5rem auto;
    }

    .swiper{
        max-width: 89.75rem;
        width: 100%;
        overflow: hidden;
    }

    .controls {
        position: relative;
        min-height: 2.5rem;
        padding-bottom: 1.5rem;
    }

    .controlActions {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .content {
        display: flex;
        margin-top: 1rem;
    }

    .sets {
        width: 21.6875rem;
        margin-right: 1rem;
        overflow-y: hidden;
    }

    .videoWrapper iframe {
        width: 100%;
        height: 100%;
        flex: 1;
    }
}
