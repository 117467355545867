@import '../../../styles/viewports.css';

.wrapper {
    max-width: 45.5rem;
    width: 100%;
    margin: 0 auto 3rem auto;
}

.notifications {
    border-radius: 1rem;
    overflow: hidden;
}

.notifications > *:not(:last-child) {
    border-bottom: 1px solid var(--border-color)
}

.center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (--lg) {
    .wrapper {
        margin: 0 auto;
        padding: 1rem 0;
    }
}
