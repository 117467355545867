.wrapper {
    width: 16rem;
    margin-right: 1rem;
    margin-bottom: 1.5rem;
    padding: 1rem;
    background: var(--white);
    border: 1px solid transparent;
    border-radius: 0.5rem;
}

.wrapper.selected {
    border-color: var(--red);
}

.heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.75rem;
}

.heading > span > img {
    width: 4rem !important;
    height: 4rem !important;
    border-radius: 0.5rem;
}

.name {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 15rem;
    overflow: hidden;
}

.description {
    margin: 0.25rem 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 15rem;
    overflow: hidden;
}
