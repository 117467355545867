@import '../../../styles/viewports.css';

.avatar {
    position: relative;
    width: calc(1rem * var(--size));
    height: calc(1rem * var(--size));
    background-color: #e8e8e8;
    border-radius: 50%;
}

.avatar img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}

.status {
    width: .75rem;
    height: .75rem;
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: .75rem;
    border: .125rem solid var(--gray-2);
}

.status > * {
    width: .625rem;
    height: .625rem;
    border-radius: .625rem;
}

.status-online {
    background-color: var(--green)
}

.status-offline {
    background-color: var(--gray-4)
}

.status-busy {
    background-color: var(--red)
}

@media (--lg) {
    .avatar:not(.rounded) img {
        border-radius: 0.5rem;
    }
}
