.inputs {
    margin-top: 2rem;
}

.inputs > * + * {
    margin-top: 0.5rem;
}

.controls {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.actions {
    padding-top: 1.5rem;
    margin-top: auto;
}

.input {
    width: 100% !important;
}

.input > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.input input {
    font-size: 1rem !important;
    font-weight: 500 !important;
    font-family: var(--base-font-family) !important;
    border: 1px solid var(--border-color) !important;
    border-radius: 0.5rem !important;
}

.input input::placeholder {
    font-size: 2rem;
}
