@import '../../../styles/viewports.css';

.button {
    height: 3rem;
    display: flex;
    align-items: center;
    padding: 0.75rem;
    color: var(--dark);
    text-decoration: none;
    cursor: pointer;
    font-weight: 500;
}

.button.active {
    color: var(--red);
    background-color: var(--red-transparent);
    border-radius: 0.5rem;
}

.button svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    fill: currentColor;
}

.button span {
    font-size: 0.9rem;
    white-space: nowrap;
    font-weight: 500;
}

@media (--xl) {
    .button span {
        font-size: 1rem;
    }
}
