.input-wrapper > * input:-webkit-autofill,
.input-wrapper > * input:-webkit-autofill:hover,
.input-wrapper > * input:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--dark);
    -webkit-box-shadow: 0 0 0 62.5rem var(--white) inset;
    caret-color: var(--dark);
}

.input-base {
    width: 100%;
}

.input-wrapper {
    position: relative;
    width: 100%;
    height: 3.5rem;
    display: flex;
    background-color: var(--white);
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    overflow: hidden;
}

.input-wrapper.background-gray {
    background-color: var(--gray-8);
}

.input-wrapper.multiline {
    height: auto;
}

.input-wrapper.disabled {
    opacity: 0.8;
}

.borderless {
    border: 0;
}

.input-wrapper:hover,
.input-wrapper:focus-within {
    border-color: var(--border-color);
}

.input-container {
    position: relative;
    display: flex;
    flex: 1;
}

.input-container > textarea {
    font-family: var(--base-font-family);
    color: var(--dark);
    font-size: 0.875rem;
    resize: vertical;
}

.success {
    background-color: var(--green-transparent) !important;
    border-color: var(--green);
}

.danger {
    background-color: var(--red-transparent) !important;
    border-color: var(--red);
}

/* w-full h-full flex-1 pl-4 text-white font-bold bg-transparent focus:outline-none */
.input {
    width: 100%;
    flex: 1;
    padding-left: 1rem;
    color: var(--dark);
    font-weight: 400;

    border: 0;
    outline: 0 !important;
    background: transparent;
    appearance: none;
}

textarea.input {
    resize: none;
}

textarea.input::-webkit-scrollbar {
    width: 0.5rem;
    background-color: var(--gray-5);
    border-radius: 1rem;
}

textarea.input::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: var(--gray-4);
}

.input.labeled {
    padding-top: 1rem;
}

textarea.input.labeled {
    padding-top: 2rem;
}

.input::placeholder {
    color: var(--gray-5);
}

.labelCover {
    position: absolute;
    left: 0;
    right: 0.5rem;
    top: 0;
    z-index: 1;
    pointer-events: none;
    height: 2rem;
    background: var(--white);
    border-top-left-radius: 0.5rem;
}

/* flex items-center justify-center pl-4 */
.leading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1rem;
}

.leading svg {
    fill: var(--dark);
    width: 1.5rem;
    height: auto;
}

.leading-divider {
    width: 1px;
    height: 1.5rem;
    margin: auto 0;
    border-right: 1px solid var(--border-color);
}

.maxCharCountWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
    color: var(--gray-2);
}

.label {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    color: var(--gray-5);
    font-weight: 500;
    pointer-events: none;
    transition: all 0.2s ease;
}

.label.active {
    padding-top: 0.625em;
    padding-bottom: 2rem;
    font-size: 0.6875rem;
    color: var(--dark);
}

.label.multiline {
    padding-top: 1em;
    align-items: flex-start;
}
