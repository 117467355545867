@import '../../../styles/viewports.css';

.container {
    max-width: 89.75rem;
    width: 100%;
    margin: 0 auto;
    padding: 0;
}

.mobileWrapper{
    padding-bottom: 2rem;
}

.mobileWrapper > * + * {
    padding: 0 1rem;
}

.cards {
    margin-bottom: 3rem;
}

.cards > *:not(:last-child) {
    margin-bottom: 0.75rem;
}

.tabs {
    height: 3rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    background: var(--white);
    margin-bottom: 1rem;
    position: sticky;
    position: -webkit-sticky;
    top: calc(5.5rem + env(safe-area-inset-top));
    z-index: 2;
}

.tab {
    width: 100%;
    height: 100%;
    font-size: 0.75rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--gray-5);
}

.tab.activeTab {
    border-bottom: 2px solid var(--red);
    color: var(--dark);
}

.performance {
    display: grid;
    grid-template-rows: 7.625rem 7.625rem 7.625rem;
    gap: 1rem;
}

.performance > * {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 7.625rem;
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
}

.userCard {
    width: 100%;
    height: 4.5rem;
    align-items: center;
    border-radius: 0.5rem;
    padding: 0 2.5rem 0 1rem;
    background: var(--white);
    display: grid;
    grid-template-columns: 1fr 10rem;
    gap: 1rem;
}

.userCard > * {
    display: grid;
    grid-template-columns: 3rem 1fr;
    gap: 1rem;
}

.userCard > * {
    white-space: nowrap;
}

.performanceWrapper {
    display: flex;
    flex-direction: column;
}

.performanceWrapper > *:nth-child(1) {
    margin-bottom: 0.5rem;
}

.performanceWrapper > * + * {
    display: grid;
    grid-template-columns: 21.5rem 1fr;
    gap: 1rem;
}

.searchResult {
    margin-top: 1rem;
    scrollbar-color: var(--gray-5) var(--gray-6);
    scrollbar-width: thin;
    overflow-y: auto;
}

.searchResult::-webkit-scrollbar {
    width: 0.5rem;
    background-color: var(--gray-6);
    border-radius: 1rem;
}

.searchResult::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: var(--gray-5);
}

.searchResult > * {
    display: grid;
    grid-template-columns: 3rem 1fr auto;
    gap: 1rem;
    align-items: center;
    cursor: pointer;
    height: 4rem;
}

.searchResult > *:hover {
    opacity: 0.7;
}

.searchResult > *:not(:last-child) {
    border-bottom: 1px solid var(--border-color)
}

.center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (--xl) {
    .performance {
        grid-template-rows: unset;
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (--lg) {
    .mobileWrapper{
        padding-bottom: 0;
    }

    .cards {
        margin-bottom: 0;
    }

    .container {
        padding: 0 1rem;
    }

    .wrapper {
        margin: 1rem 0;
        display: grid;
        grid-template-columns: 21.5rem 1fr;
        gap: 1rem;
    }
}
