:root {
    /*
    * Colors
    */
    --white: #fff;
    --light: #f9f9f9;
    --light-transparent: rgba(255, 255, 255, 0.1);
    --dark: rgb(51, 67, 98);
    --dark-transparent: rgba(30, 38, 109, 0.1);
    --dark-transparenter: rgba(30, 38, 109, 0.05);
    --black-transparent: rgba(0, 0, 0, 0.06);
    --dark-blue: rgb(26, 35, 126);
    --backdrop: rgba(0, 0, 0, 0.3);
    --backdrop-hover: rgba(0, 0, 0, 0.6);

    --gray-1: #f7faff;
    --gray-2: rgb(79, 93, 130);
    --gray-3: rgba(79, 93, 130, 0.7);
    --gray-4: rgba(79, 93, 130, 0.08);
    --gray-5: rgb(129, 139, 165);
    --gray-6: rgba(124, 129, 172, 0.2);
    --gray-7: rgba(124, 129, 172, 0.1);
    --gray-8: rgba(129, 139, 165, 0.1);
    --gray-9: rgba(129, 139, 165, 0.2);
    --gray-10: rgba(79, 93, 130, 0.2);

    --red: rgb(227, 53, 42);
    --red-transparent: rgba(227, 53, 42, 0.1);
    --red-transparent-3: rgba(227, 53, 42, 0.3);
    --green: rgb(14, 214, 28);
    --green-transparent: rgba(14, 214, 28, 0.2);
    --dark-green: rgb(14, 150, 28);
    --blue: rgb(14, 29, 214);
    --blue-transparent: rgba(14, 29, 214, 0.2);
    --orange: rgb(255, 89, 63);
    --orange-transparent: rgba(255, 89, 63, 0.2);
    --yellow: rgb(255, 153, 16);
    --yellow-transparent: rgba(255, 153, 16, 0.2);
    --pink: rgb(255, 67, 91);
    --pink-transparent: rgba(255, 67, 91, 0.2);

    --border-color: rgba(51, 67, 98, 0.2);

    --navigation-item: var(--dark);
    --navigation-item-icon: var(--red);
    --navigation-item-bg: var(--red-transparent);

    /*
    * Shadows
    */
    --shadow-1: 0 0.625rem 3rem rgba(0, 0, 0, 0.05);
    --shadow-2: 0 1rem 2rem rgba(0, 0, 0, 0.05);

    /*
    * Fonts
    */
    --base-font-family: 'Red Hat Display', sans-serif;
    --base-font-lineheight: 1.5;

    /**
    * Z-indexes
    */
    --z-index-0: 0;
    --z-index-1: 10;
    --z-index-2: 20;
    --z-index-3: 30;
    --z-index-4: 40;
    --z-index-5: 50;
}
