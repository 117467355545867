.card {
    width: 8.5rem;
    height: 6.375rem;
    position: relative;
    margin: 1rem 1rem 2.5rem 1rem;
    background: var(--white);
    border-radius: 1rem;
    box-shadow: var(--shadow-2);
}

.card.owned {
    border: 1px solid #E3352A;
}

.packageTypeText {
    position: absolute;
    top: -0.6875rem;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 6.25rem;
    background: #FEEDE5;
    align-items: center;
    text-align: center;
    border-radius: 0.1875rem;
    padding: 0.1875rem 0;
}

.cardInfo {
    border-radius: 1rem;
    padding: 1.625rem 1.375rem 0.6875rem;
    text-align: center;
}

.payAmountText {
    margin-bottom: 0.5rem;
}

.card.current {
    border: 3px solid #E3352A;
}

.packageTypeText.current {
    background: #E3352A;
}


