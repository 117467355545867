@import '../../../styles/variables.css';

.horizontalDaySelection {
    flex: 0 0 8rem;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    height: 8rem;
    /*scrollbar-color: var(--gray-5) var(--gray-6);*/
    /*scrollbar-width: thin;*/
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/*.horizontalDaySelection::-webkit-scrollbar {*/
/*    height: 0.5rem;*/
/*    background-color: var(--gray-6);*/
/*    border-radius: 1rem;*/
/*}*/

/*.horizontalDaySelection::-webkit-scrollbar-thumb {*/
/*    border-radius: 1rem;*/
/*    background-color: var(--gray-5);*/
/*}*/

.horizontalDaySelection::-webkit-scrollbar {
    display: none;
}

.verticalDaySelection {
    flex: 0 0 12rem;
    height: 35.375rem;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
    border-radius: .5rem;
    padding: 0 1rem;
    background: var(--white);
    scrollbar-color: var(--gray-5) var(--gray-6);
    scrollbar-width: thin;
}

.verticalDaySelection::-webkit-scrollbar {
    width: 0.5rem;
    background-color: var(--gray-6);
    border-radius: 1rem;
}

.verticalDaySelection::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: var(--gray-5);
}
