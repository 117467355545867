@import '../../../styles/viewports.css';

.card-heading {
    position: relative;
    display: flex;
    align-items: center;
    padding: 1rem 1rem 0;
}

.card-heading.left {
    justify-content: flex-start;
    text-align: left;
}

.card-heading.center {
    justify-content: center;
    text-align: center;
}

.card-heading.right {
    justify-content: flex-end;
    text-align: right;
}

.card-heading.divided {
    margin: 0 1rem;
    padding: 1rem 0;
    border-bottom: 1px solid var(--dark-transparent);
}

.card-heading .title {
    color: var(--dark);
    font-weight: 500;
}

.card-heading.lg .title {
    font-size: 1.125rem;
}

.card-heading.xl .title {
    font-size: 1.5rem;
}

.card-heading .trailing {
    align-items: center;
    display: flex;
    position: absolute;
    right: 0;
}

.card-heading .leading {
    position: absolute;
    left: 0;
    align-items: center;
    display: flex;
}

@media (--lg) {
    .card-heading.lg .title {
        font-size: 1.5rem;
    }

    .card-heading.xl {
        padding: 2rem 1rem 0;
    }

    .card-heading.xl .title {
        font-size: 2rem;
    }
}
