.wrapper {
    height: 4.5rem;
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    background: var(--white);
    border-radius: 0.5rem;
    background: var(--white);
    cursor: pointer;
}

@media (hover: hover) {
    .wrapper:hover {
        background: var(--gray-6);
    }
}

.body {
    flex: 1;
    padding: 0 1rem;
    line-height: 1.4;
}

.icon {
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--dark-blue);
}
