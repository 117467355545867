.card {
    width: 100%;
    border-radius: 1rem;
    box-shadow: var(--shadow-1);
}

.card.type-default {
    background-color: var(--gray-2);
}

.card.type-darken {
    background-color: var(--gray-3);
}

.card.type-light {
    background-color: var(--white);
    box-shadow: none;
}

.border-none {
    border: 0;
}

.border-default {
    border: 0.125rem solid var(--border-color);
}

.border-danger {
    border: 0.125rem solid var(--red);
}
