@import '../../../styles/viewports.css';

.container {
    width: 100%;
}

.container > * {
    padding: 0 1.5rem 1.5rem;
}

.buttonsWrapper {
    display: flex;
    flex: 1;
}

.buttonsWrapper > * {
    flex: 1;
    margin: 0 1rem;
}

.inputWrapper {
    margin-bottom: 1rem;
}

.inputWrapper :nth-child(2) {
    margin-bottom: 0.5rem;
}

@media (--sm) {

    .buttonsWrapper {
        justify-content: center;
    }

    .buttonsWrapper > * {
        max-width: 12.5rem;
    }
}
