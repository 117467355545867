@import '../../../styles/viewports.css';

.wrapper {
    flex: 1;
    height: 100%;
}

.vid {
    background-color: var(--black-transparent);
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.vid.fullVideo > * video {
    object-fit: contain !important;
}

.vid.no-cam {
    fill: #fff;
    display: flex;
    justify-content: center;
    align-items: center
}

.videos {
    position: relative;
    display: grid;
    height: 100%;
    margin: auto;
}

.videos .video {
    width: 100%;
    height: 100%;
}

.videos .video.inactive {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    z-index: 1;
    width: 6rem;
    height: 8rem;
}

.live {
    height: 100%;
    display: grid;
    grid-template-rows: 1fr;
}

.footer {
    position: absolute;
    top: calc(6rem + env(safe-area-inset-top));
    left: 1rem;
    display: grid;
    gap: 0.5rem;
    grid-template-rows: repeat(3, 2fr);
    z-index: 2;
}

.footerGroup {
    display: flex;
}

.footerGroup > * + * {
    margin-left: 0.5rem
}

.audioSlider {
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 2rem;
    align-items: center;
    fill: var(--dark)
}

.hide {
    animation: hide 0.1s forwards;
}

.notHide {
    animation: notHide 0.1s forwards;
}

@keyframes hide {
    0% {
        opacity: 1;
        margin-left: 0;
    }

    99% {
        margin-left: 0;
    }

    100% {
        margin-left: -9999px;
        opacity: 0;
    }
}

@keyframes notHide {
    100% {
        opacity: 1;
        margin-left: 0;
    }

    1% {
        margin-left: 0;
    }

    0% {
        margin-left: -9999px;
        opacity: 0;
    }
}


@media (--lg) {


    .videos .video.inactive {
        width: 8rem;
        height: 8rem;
    }
}
