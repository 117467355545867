.card {
    padding: 1.1875rem 1.5rem 1rem;
    background: var(--white);
    border-radius: 0.5rem;
    width: 100%;
    height: 10.9375rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card.current {

}

.card.type-danger {
    background: var(--red);
}

.card.type-danger > button {
    background: var(--white);
    color: var(--red);
}

.card.type-danger > button:hover {
    color: var(--white);
}

.packageTypeText {
    display: inline-block;
    border: 1px solid var(--red);
    border-radius: 0.1875rem;
    padding: 0.5rem;
    margin-left: 0.1875rem;
}

.packageTypeText.danger {
    border: 1px solid var(--white)
}

.packageTypeText.danger > * {
    color: var(--white)
}

.monthAmount {
    margin-top: 0.6875rem;
}

.monthAmount.danger {
    color: var(--white)
}

.payAmount.danger {
    color: var(--white)
}

.cardInfo {
    display: flex;
    justify-content: space-between;
}
