.wrapper {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.wrapper.reverse{
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
}

.wrapper input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
}

.checkbox {
    width: 1.5rem;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    border: 1px solid var(--border-color);
    border-radius: 0.25rem;
}

.checkbox:hover, .checkbox:focus{
    border-color: var(--dark);
}

.checkbox.circle {
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
}

.checkbox svg {
    width: 1rem;
    height: 1rem;
    fill: #fff;
    display: none;
}

.wrapper input:checked ~ .checkbox {
    background-color: var(--red);
    border-color: var(--red);
}

.wrapper input:checked ~ .checkbox svg {
    display: block;
}

.label {
    margin-left: 0.5rem;
    color: var(--dark);
    font-weight: 500;
    overflow-wrap: anywhere;
}
