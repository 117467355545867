@import '../../../styles/viewports.css';

.wrapper {
    user-select: none;
}

.top {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.back-button {
    fill: var(--dark);
    position: absolute;
    left: 0;
    top: 0.25rem;
    z-index: 3;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
}

.close-button, .close {
    cursor: pointer;
    fill: var(--dark);
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
    display: flex;
    align-items: center;
}

.content {
    margin-top: 2rem;
    text-align: center;
}

.webWrapper {
    position: relative;
    text-align: center;
}

@media (--lg) {
    .wrapper {
        padding: 0
    }

}
