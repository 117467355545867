@import '../../../styles/viewports.css';

.container {
    width: 100%;
}

.container > * {
    padding: 1rem;
}

.buttonsWrapper {
    display: flex;
    flex: 1;
}

.buttonsWrapper > * {
    flex: 1;
    margin: 0;
}

.descriptionWrapper {
    margin-bottom: 1rem;
    text-align: center;
}

@media (--sm) {
    .buttonsWrapper {
        justify-content: center;
    }

    .buttonsWrapper > * {
        max-width: 12.5rem;
        margin: 0 1rem;
    }
}
