.notification {
    display: grid;
    grid-template-columns: 3rem 1fr auto;
    gap: 0.5rem;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    cursor: pointer;
    background: var(--white);
}

.notification.unRead {
    background: var(--gray-4);
}

.notification:hover {
    background: var(--red-transparent);
}

.icon {
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--red);
    border-radius: 1.5rem;
}

.icon > svg {
    fill: var(--white);
    width: 1.5rem;
    height: 1.5rem;
}

.texts {
    display: grid;
    grid-template-rows: 1fr 1fr;
}

.texts > * {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
