.wrapper {
    position: relative;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--gray-2);
    background: var(--gray-7);
    border-radius: 1.5rem;
}

.wrapper.full {
    color: var(--white);
    background: var(--red);
}

.wrapper.inProgress {
    color: var(--red);
    background: var(--red-transparent);
}

.wrapper svg {
    fill: currentColor;
}

.progressbar {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}
