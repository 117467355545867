@import '../../../styles/viewports.css';

.wrapper {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.wrapper .video {
    position: relative;
    z-index: var(--z-index-1);
    width: 100%;
    height: 60vw;
}

.video > * {
    width: 100%;
    height: 100%;
}

.video > * > iframe {
    width: 100%;
    height: 100%;
}

.wrapper .infoCardWrapper {
    position: relative;
    z-index: var(--z-index-2);
    margin: 1rem;
}

.wrapper .content {
    margin: 0 1rem 4.5rem;
    padding-bottom: 5rem;
}

.wrapper .limitedContent {
    position: relative;
}

.wrapper .limitedContentItems {
    margin: 0 1rem 8.5rem 1rem;
}

.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(100% - 8.5rem);
    width: 100%;
}

.backdrop > * {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}

.wrapper .limitedContentButtonWrapper {
    position: absolute;
    bottom: 4.5rem;
    left: 1rem;
    right: 1rem;
    z-index: var(--z-index-3);
}

.wrapper .content > * + *,
.wrapper .limitedContentItems > * + * {
    margin-top: 0.5rem;
}

.privacyButton {
    margin-top: 0.5rem
}

.wideWrapper {
    display: none;
}

.trainerCard {
    display: flex;
    align-items: center;
}

.trainerCard > * + * {
    margin-left: 1rem;
}

@media (--lg) {
    .wrapper {
        display: none;
    }

    .wrapper .content {
        padding-bottom: 0;
    }

    .wideWrapper {
        max-width: 89.75rem;
        width: 100%;
        display: grid;
        grid-template-columns: 21rem auto 29rem;
        gap: 1rem;
        margin: 2rem auto;
    }

    .wideWrapper .content {
        margin-top: 1rem;
    }

    .wideWrapper .content > * + * {
        margin-top: 0.5rem;
    }

    .wrapper .infoCardWrapper {
        margin: 0 1rem 1rem;
    }
}
