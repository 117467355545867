.card {
    width: 100%;
    background: var(--white);
    border-radius: 0.5rem;
    padding: 1.5rem 1.875rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    box-shadow: var(--shadow-1);
}

.card > *:nth-child(2) {
    text-align: center;
    width:10.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.buttonInspector {
    width: 100%;
    margin-top: 1rem;
}

.dateText {
    color: #4F5D82;
    font-size: 0.875rem;
    margin-top: 0.5rem;
}

.broadcastName {
    margin-top: 1rem;
}
