@import '../../../styles/viewports.css';

.wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.wrapper.owner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.card {
    max-width: 18rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.25rem 0;
    border-radius: 0.5rem;
    color: #fff;
    background: rgba(0, 0, 0, 0.3);
}

.card > *:not(:nth-child(2)) {
    margin: 0 0.5rem;
}

.card > *:nth-child(3) {
    white-space: pre;
}

.card.owner {
    flex-direction: row-reverse;
}

.messageText > *  {
    max-width: 8rem;
    word-wrap: break-word;
}

@media (--sm) {
    .card {
        max-width: 48rem;
    }

    .messageText > *  {
        max-width: 25rem;
    }
}


@media (--lg) {
    .card {
        max-width: 32rem;
    }

    .messageText > *  {
        max-width: 16rem;
    }
}
