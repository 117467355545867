.button {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0;
    padding: 0;
    cursor: pointer;
    color: var(--gray-3);
    background: none;
    border: 0;
    outline: 0 !important;
    display: flex;
    align-items: center;
    justify-items: center;
}

@media (hover: hover) {
    .button:hover {
        color: var(--pink);
    }
}

.button svg {
    fill: currentColor;
    width: 1.35rem;
    height: 1.35rem;
}

