.container {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.5rem;
}

.card {
    width: 21.6875rem;
    height: 5rem;
    border-radius: 0.5rem;
    background: white;
    display: flex;
    align-items: center;
    padding: 0 1.4375rem 0 1rem;
}

.cardInfo {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin-left: 0.75rem;
}
