.wrapper {
    width: 100%;
    border-radius: 1rem;
    background-color: var(--white);
}

.title {
    padding: 1rem;
}

.item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem;
}

.item:not(:first-child) {
    border-top: 1px solid var(--gray-6);
}

.item .trailing {
    margin-left: auto;
}
