*,
*::before,
*::after {
    box-sizing: border-box;
    font-family: var(--base-font-family) !important;
    font-size: 16px;
}

* {
    -webkit-tap-highlight-color: transparent;
}

html,
body {
    color: var(--dark);
    font-weight: 400;
    line-height: var(--base-font-lineheight);
    -webkit-font-smoothing: antialiased;
    background-color: var(--gray-1);
    min-width: 21.25rem;
    margin: 0;
    height: 100%;
}

body > iframe {
    display: none;
}

#root {
    display: flex;
    flex-direction: column;
    padding: calc(5.5rem + env(safe-area-inset-top)) 0 calc(3.5rem + env(safe-area-inset-bottom)) 0;
    height: 100%;
    position: relative;
}

a {
    text-decoration: none;
}

button {
    border: 0;
    outline: 0;
    appearance: none;
    background-color: transparent;
    cursor: pointer;
    text-decoration: none !important;
}

input {
    background-color: var(--white);
}

abbr[title] {
    text-decoration: none !important;
}

/*React Calendar*/
.react-calendar {
    font-family: var(--base-font-family) !important;
    border: none !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus,
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: var(--light) !important;
}

.react-calendar__tile:disabled {
    background-color: var(--white) !important;
}

.react-calendar__month-view__days__day,
.react-calendar__navigation__label,
.react-calendar__navigation__arrow,
.react-calendar__tile,
.react-calendar__year-view__months__month {
    color: #000
}

.react-calendar__month-view__days__day--weekend:disabled,
.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__navigation__arrow:disabled,
.react-calendar__navigation__next-button:disabled,
.react-calendar__tile:disabled,
.react-calendar__century-view__decades__decade:disabled {
    color: rgba(16, 16, 16, 0.3)
}

.react-calendar__navigation button[disabled] {
    background-color: var(--white) !important;
}

.react-calendar__month-view__weekdays, .react-calendar__month-view__days {
    padding: 0 0.5rem;
}

.Toastify__toast {
    border-radius: 0.5rem !important;
    margin: 1rem;
}

.swiper-pagination-bullet-active {
    background: var(--dark) !important;
}

.swiper-button-disabled {
    background: var(--blue-transparent) !important;
    fill: var(--blue) !important;
    opacity: 0.4;
    cursor: default;
}

@media (--lg) {
    #root {
        padding-bottom: 0;
    }
}

@media (--sm) {
    .Toastify__toast {
        margin: 0 0 0.5rem 0;
    }
}
