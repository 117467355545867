.wrapper {
    position: relative;
    width: 100%;
    background-color: var(--white);
    border-radius: 0.5rem;
}

.heading {
    height: 5rem;
    display: flex;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid var(--gray-6);
}

.heading > span > img{
    width: 3rem!important;
    height: 3rem!important;
    border-radius: 0.5rem;
}

.title {
    flex: 1;
    margin: 0 1rem;
}

.body {
    padding: 1rem;
}
