.inputs {
    margin-top: 2rem;
}

.inputs > * + * {
    margin-top: 0.5rem;
}

.controls {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.actions {
    padding-top: 1.5rem;
    margin-top: auto;
}
