.wrapper {
    padding: 1rem;
    background: var(--white);
    border-radius: 0 0 0.5rem 0.5rem;
    border-top: 1px solid var(--gray-6);
}

.wrapper.scrollable {
    height: 100%;
    border-top: 0;
    border-radius: 0.5rem;
    overflow-y: auto;
}

.wrapper::-webkit-scrollbar {
    width: 0.5rem;
    background-color: var(--gray-6);
    border-radius: 1rem;
}

.wrapper::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: var(--gray-5);
}

.separator {
    width: 100%;
    height: 1px;
    margin: 1rem 0;
    background: var(--gray-6);
    border-radius: 1px;
}

.center{
    text-align: center;
    padding: 0.5rem 0;
}

.item {
    display: flex;
    align-items: center;
}

.item > span > img {
    width: 3rem !important;
    height: 3rem !important;
    object-fit: cover;
    object-position: center;
    border-radius: 0.5rem;
}

.itemBody {
    flex: 1;
    padding: 0 1rem;
    line-height: 1.4;
}
