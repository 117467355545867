@import '../../../styles/viewports.css';

.wrapper {
    max-width: 45.5rem;
    width: 100%;
    margin: 0 auto 3rem auto;
    padding: 1rem;
}

.disclosure {
    width: 100%;
    display: flex;
    flex-direction: column;
    fill: var(--dark);
    margin-bottom: 1rem;
}

.button {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1.5rem;
    justify-content: space-between;
    align-items: center;
    color: var(--dark);
    border: 0;
    border-radius: 1rem;
    min-height: 3.5rem;
    background: var(--white);
    font-weight: 500;
    font-size: 1.125rem;
    text-align: left;
    padding: 1.5rem;
}

.button.open {
    border-radius: 1rem 1rem 0 0;
}

.panel {
    background: var(--white);
    color: var(--dark);
    padding: 0 1.5rem 1.5rem;
    border-radius: 0 0 1rem 1rem;
}

.area {
    padding-bottom: 3rem;
}

.title > h2 {
    display: none;
}

@media (--lg) {
    .wrapper {
        margin: 0 auto;
    }

    .area {
        padding-bottom: 0
    }

    .title > h2 {
        display: block;
        margin: 0 0 1rem 0;
        color: var(--dark);
        font-weight: 500;
        font-size: 1.5rem;
        text-align: center;
    }
}
