@import '../../../styles/viewports.css';

.navigationBar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: var(--z-index-4);
    max-width: 100rem;
    width: 100%;
    margin: 0 auto;
    background-color: var(--white);
    padding-top: env(safe-area-inset-top);
}

.container {
    max-width: 89rem;
    width: 100%;
    height: 5.5rem;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    margin: 0 auto;
}

.logo {
    height: 2.5rem;
    cursor: pointer;
    display: block;
}

.titleWrapper {
    display: flex;
    align-items: center;
}

.titleWrapper .backButton {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    background: transparent;
    border: 0;
    outline: 0;
}

.titleWrapper .backButton svg {
    fill: var(--dark);
}

.menu {
    display: none;
    margin-left: auto;
}

.menu > * + * {
    margin-left: 0.5rem;
}

.menuTrigger {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.menuTrigger button {
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    border-radius: 0.5rem;
    border: 1px solid var(--border-color);
}

.menuTrigger button svg {
    fill: var(--gray-2);
}

.menuTrigger.leadButton button {
    border: 1px solid var(--red);
}

.menuTrigger.leadButton button svg {
    fill: var(--red);
}

.auth {
    display: none;
    align-items: center;
    margin-left: auto;
}

.auth > * + * {
    margin-left: 0.5rem;
}

.auth > * {
    width: 6.25rem;
    height: 3rem;
}

.icons {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.icons > * + * {
    margin-left: 1rem;
}

.dropdown {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 5.5rem;
    margin-top: env(safe-area-inset-top);
    z-index: var(--z-index-5);
    background: var(--gray-1);
    border-top: 1px solid var(--gray-6);
    overflow-y: auto;
}

.dropdownItems {
    display: flex;
    flex-direction: column;
    background: var(--white);
}

.dropdownItem {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem;
    color: var(--dark);
    font-weight: 500;
    text-decoration: none;
}

.dropdownItemLogout {
    color: var(--red);
    border-top: 1px solid var(--gray-6);
}

.dropdownItem span {
    flex: 1;
    padding: 0 1rem;
}

.dropdownItem .icon {
    fill: var(--red);
}

.dropdownItem .icon2 {
    fill: var(--gray-5);
}

.mobile-dropdown {
    padding: 1rem;
    background: var(--gray-1);
}

.mobile-dropdown > * {
    height: 3.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.5rem;
    padding: 0 1rem 0 1.5rem;
    margin: 0.5rem 0;
    fill: var(--dark);
    background: var(--white);
}

.danger {
    background: var(--red-transparent);
    fill: var(--red);
}

.danger > * {
    color: var(--red) !important;
}


@media (--lg) {
    .menu {
        display: flex;
    }

    .auth {
        display: flex;
    }

    .menuTrigger {
        display: none;
    }

    .menu > * + * {
        margin-left: 1rem;
    }

    .dropdown {
        display: none;
    }

    .navigationBar {
        border-radius: 0 0 1rem 1rem;
    }
}

@media (--xl) {
    .logo {
        height: 3.5rem;
    }

    .auth > * {
        width: 8.75rem;
    }
}
