.wrapper {
    padding: 0.625rem;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    border-radius: 0.5rem;
}

.wrapper.default {
    background: var(--gray-1);
}

.wrapper.red {
    color: var(--white);
    background: var(--red);
}

.wrapper.redTransparent {
    color: var(--red);
    background: var(--red-transparent);
}

.wrapper.greenTransparent {
    color: var(--green);
    background: var(--green-transparent);
}

.wrapper.green {
    color: var(--white);
    background: var(--green);
}

.wrapper.small {
    padding: 0.375rem 0.625rem;
    font-size: 0.75rem;
    font-weight: 500;
}

.wrapper.rounded {
    border-radius: 10rem;
}

.wrapper svg {
    width: 1.25rem;
    height: 1.25rem;
    display: block;
    fill: currentColor;
}
