.area {
    position: relative;
}

.button {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 1.75rem;
    border: 1px solid var(--border-color);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: 0;
    appearance: none;
    background-color: transparent;
    text-decoration: none !important;
}

.button:hover {
    background: var(--red-transparent);
}

.button > svg {
    fill: var(--dark)
}

.button.open {
    border: none;
    background: var(--red);
}

.button.open > svg {
    fill: var(--white);
}

.new {
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
    border: 2px solid var(--white);
    width: 0.8rem;
    height: 0.8rem;
    background: var(--red);
    border-radius: 0.4rem;
}

.new.open {
    background: var(--white);
    border: 2px solid var(--red);
}

.dropdown {
    position: absolute;
    top: 4rem;
    right: 0;
    background: var(--white);
    border-radius: 0.5rem;
    border: 1px solid var(--red);
    overflow: hidden;
}

.wrapper {
    width: 21.75rem;
    height: 100%;
    max-height: 16.25rem;
}

.wrapper > *:not(:last-child) {
    border-bottom: 1px solid var(--border-color)
}
