@import '../../../styles/viewports.css';

.card {
    width: 10.375rem;
    height: 13.5rem;
    margin-right: 1rem;
    position: relative;
    border-radius: 0.5rem;
    overflow: hidden;
    background: #fff;
    display: flex;
    justify-content: center;
}

.card > span > img {
    width: 8.950rem !important;
    height: 8rem !important;
    border-radius: 0.25rem;
    margin: 0.75rem auto;
    object-fit: cover;
    object-position: center;
}

.name {
    position: absolute;
    bottom: 3.25rem;
    left: 0.75rem;
    right: 0.75rem;
    z-index: 2;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.description {
    position: absolute;
    bottom: 2.25rem;
    left: 0.75rem;
    right: 0.75rem;
    z-index: 2;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.trainer {
    position: absolute;
    bottom: 0.75rem;
    left: 0.75rem;
    right: 0.75rem;
    z-index: 2;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@media (--lg) {
    .card {
        width: 12.375rem;
        height: 15.5rem;
    }

    .card > span > img {
        width: 10.950rem !important;
        height: 10rem !important;
    }

}
