@import '../../../styles/viewports.css';

.tab-bar {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: var(--z-index-3);
    height: calc(3.5rem + env(safe-area-inset-bottom));
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: var(--white);
    box-shadow: var(--shadow-1);
}

.button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    fill: var(--dark)
}

.active {
    border-top: 2px solid var(--red);
    fill: var(--red)
}

.new {
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
    border: 2px solid var(--white);
    width: 0.8rem;
    height: 0.8rem;
    background: var(--red);
    border-radius: 0.4rem;
}
