.inputs {
    margin-top: 2rem;
}

.inputs > * + * {
    margin-top: 0.5rem;
}

.controls {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 1.5rem;
}

.kvkkLabel {
    margin-left: 0.5rem;
}

.actions {
    padding-top: 1.5rem;
    margin-top: auto;
}

.controls > *:nth-child(2) {
    margin-left: 0.5rem;
}

.controls > *:nth-child(3) {
    margin-left: 0.2rem;
}

.contentWrapper {
    margin-bottom: 1rem;
    max-height: calc(100vh - 10rem);
    overflow: auto;
    padding-right: 1.5rem;
    white-space: pre-wrap;
}

.contentWrapper::-webkit-scrollbar {
    width: 0.5rem;
    background-color: var(--gray-6);
    border-radius: 1rem;
}

.contentWrapper::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: var(--gray-5);
}

.back {
    display: flex;
    align-items: center;
    fill: var(--dark)
}
