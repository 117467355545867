.button {
    position: relative;
    height: 3.5rem;
    display: flex;
    align-items: center;

    font-size: 1rem;
    font-weight: 500;

    transition: all 0.25s ease;

    /* Reset */
    border: 0;
    outline: 0;
    appearance: none;
    background-color: transparent;
    cursor: pointer;
    text-decoration: none !important;
}

.button.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.button.unClick {
    pointer-events: none;
}

.button.unClick:hover, .button.unClick:focus {
    cursor: default !important;
}

/*#region Types*/
.button.type-default {
    color: var(--gray-2);
    background: var(--white);
    border: 1px solid var(--border-color);
}

.button.type-default:hover {
    background: var(--gray-4);
}

.button.type-default:focus {
    background: var(--gray-4);
}

.button.type-dimmed {
    color: var(--gray-2);
    background: var(--gray-4);
}

.button.type-dimmed:hover {
    background: var(--dark-transparent);
}

.button.type-dimmed:focus {
    color: var(--light);
    background: var(--dark);
}

.button.type-gradient {
    color: #fff;
    background: linear-gradient(45deg, var(--red), var(--orange));
}

.button.type-gradient:hover {
    background: linear-gradient(45deg, var(--orange), var(--red));
}

.button.type-gradient:focus {
    background: linear-gradient(45deg, var(--orange), var(--yellow));
}

.button.type-danger {
    color: var(--red);
    background: var(--red-transparent);
}

.button.type-danger:hover {
    background: var(--orange-transparent);
}

.button.type-danger:focus {
    color: var(--white);
    background: var(--orange);
}

.button.type-danger-2 {
    color: var(--white);
    background: var(--red);
}

.button.type-danger-2:hover {
    background: var(--orange);
}

.button.type-danger-2:focus {
    color: var(--red);
    background: var(--red-transparent);
}

.button.type-danger-3 {
    color: var(--red);
    border: 1px solid var(--red);
    background: var(--gray-2);
}

.button.type-danger-3:hover {
    color: var(--orange);
    border: 1px solid var(--orange);
}

.button.type-danger-3:focus {
    color: var(--yellow);
    border: 1px solid var(--yellow);
}

.button.type-danger-4 {
    color: var(--white);
    background: var(--red-transparent-3);
    font-weight: 400;
}

.button.type-warning {
    border: 1px solid #FF8E3C;
}

.button.type-warning-fill {
    color: #FF9910;
    background: rgba(255, 153, 16, 0.1);
}

.button.type-warning-link {
    height: auto;
    padding: 0 !important;
    color: var(--yellow);
    line-height: 1.5;
}

.button.type-warning-link:hover {
    color: var(--red);
}

.button.type-warning-link:focus {
    color: var(--orange);
}

.button.type-danger-link {
    height: auto;
    padding: 0 !important;
    color: var(--red);
    line-height: 1.5;
}

.button.type-danger-link:hover {
    color: var(--yellow);
}

.button.type-danger-link:focus {
    color: var(--orange);
}

.button.type-dimmed-link {
    color: #fff;
    background: var(--gray-4);
    height: 2.25rem;
    border-radius: 0.312rem;
    font-size: 0.812rem;
}

.button.type-dimmed-link:hover {
    background: var(--gray-6);
}

.button.type-dimmed-link:focus {
    background: var(--dark);
}

.button.type-pink {
    color: #fff;
    background: var(--red);
    height: 2.25rem;
    border-radius: 0.312rem;
    font-size: 0.812rem;
}

.button.type-pink:hover {
    background: var(--orange);
}

.button.type-pink:focus {
    background: var(--yellow);
}

.button.type-pink-link {
    height: auto;
    color: var(--red);
    line-height: 1.5;
}

.button.type-pink-link:hover {
    color: var(--orange);
}

.button.type-pink-link:focus {
    color: var(--yellow);
}

.button.type-gray {
    color: var(--gray-2);
    background: var(--gray-4);
}

.button.type-gray:hover {
    background: var(--gray-6);
}

.button.type-gray:focus {
    background: var(--dark);
}

.button.type-gray-link {
    height: auto;
    color: var(--gray-4);
    line-height: 1.5;
}

.button.type-gray-link:hover {
    color: var(--gray-6);
}

.button.type-gray-link:focus {
    color: var(--dark);
}

.button.type-success {
    color: #fff;
    background: var(--green);
}

.button.type-success:hover {
    background: rgb(105, 214, 28);
}

.button.type-success:focus {
    background: rgb(165, 244, 80);
}

.button.type-light {
    color: var(--dark);
    background: var(--gray-2);
    border-radius: 1rem;
    height: 5rem;
    font-size: 1.25rem;
}

.button.type-light:hover {
    background: var(--gray-5);
}

.button.type-light:focus {
    color: #fff;
    background: var(--gray-4);
}

.button.type-success-2 {
    color: var(--green);
    background: var(--green-transparent);
}

.button.type-success-2:hover {
    color: var(--orange);
    background: var(--orange-transparent);
}

.button.type-success-2:focus {
    color: var(--light);
    background: var(--green);
}

.button.type-blured {
    color: #fff;
    backdrop-filter: blur(10px) brightness(0.9);
    -webkit-backdrop-filter: blur(10px) brightness(0.9);
    background: rgba(0, 0, 0, 0.1);
}

.button.type-blured:hover {
    background: rgba(255, 255, 255, 0.2);
}

.button.type-blured:focus {
    background: rgba(0, 0, 0, 0.2);
}

.button.type-black-opacity {
    color: #fff;
    background: rgba(0, 0, 0, 0.3);
}

/*#endregion*/

/*#region Variations*/
.button.variation-default {
    padding: 0 1rem;
    border-radius: 0.5rem;
}

.button.variation-block {
    width: 100%;
    padding: 0 1rem;
    border-radius: 0.5rem;
}

.button.variation-wide {
    padding: 0 3rem;
    border-radius: 0.5rem;
}

.button.variation-circle {
    width: 3.5rem;
    border-radius: 50%;
}

.button.variation-icon {
    width: 3rem;
    height: 3rem;
    border-radius: 0.5rem;
}

/*#endregion*/

/*#region Sizes*/
.button.size-small {
    font-size: 0.875rem;
}

.button.variation-circle.size-small {
    width: 2.5rem;
    height: 2.5rem;
}

.button.size-xSmall {
    font-size: 0.875rem;
}

.button.size-xSmall {
    width: auto;
    height: 2.5rem;
}

.button.variation-icon.size-xSmall {
    width: 2.5rem;
    height: 2.5rem;
}


/*#endregion*/

/*#region Alignments*/
.button.alignment-left {
    justify-content: flex-start;
    text-align: left;
}

.button.alignment-center {
    justify-content: center;
    text-align: center;
}

.button.alignment-right {
    justify-content: flex-end;
    text-align: right;
}

/*#endregion*/

/*#region Icons*/
.button:not(.variation-circle) .icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button:not(.variation-circle) .icon.relative.left {
    margin-right: 0.5rem;
}

.button:not(.variation-circle) .icon.relative.right {
    margin-left: 0.5rem;
}

.button.leftPadding {
    padding-left: 3.5rem;
}

.button.rightPadding {
    padding-right: 3.5rem;
}

.button:not(.variation-circle) .icon.absolute.left {
    position: absolute;
    left: 1rem;
}

.button:not(.variation-circle) .icon.absolute.right {
    position: absolute;
    right: 1rem;
}

.button svg {
    fill: currentColor;
}

/*#endregion*/

/*#region Loading*/
.button.loading {
    pointer-events: none;
}

.button.loading > * {
    display: flex;
    align-items: center;
    pointer-events: none;
}

.button.loading > .loadingIcon {
    animation: rotate 3s linear infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

/*#endregion*/
