.button {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0;
    padding: 0;
    cursor: pointer;
    color: var(--gray-3);
    background: none;
    border: 0;
    outline: 0 !important;
}

@media (hover: hover) {
    .button:hover {
        color: var(--green);
    }
}

.button.loading {
    color: var(--yellow);
}

.button svg {
    fill: currentColor;
}

.button.loading svg {
    animation: rotate 3s linear infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}
