@import '../../../styles/viewports.css';

.box {
    padding: 2rem;
    text-align: center;
    color: var(--red);
    font-size: 1rem;
    font-weight: 500;
    background: var(--red-transparent);
    border-radius: 1rem;
}

.box.align-left {
    text-align: left;
}

.box.align-center {
    text-align: center;
}

.box.size-sm {
    font-size: 0.875rem;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
}

.box.size-lg {

}

@media (--lg) {
    .box {
        font-size: 1.25rem;
    }
}
