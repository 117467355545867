@import '../../../styles/viewports.css';

.container {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: var(--z-index-4);
    display: flex;
    flex-direction: column;
    padding: 0;
    background: rgb(30, 30, 30);
}

.wrapper {
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.video {
    width: 100%;
    height: 100%;
}

.video > * {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 0;
    overflow: hidden;
}

.chat {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.topLeftArea > * + * {
    margin-top: 0.5rem;
    width: 100%;
    height: 1.5rem !important;
}

.topLeft {
    position: absolute;
    left: 1rem;
    top: calc(1rem + env(safe-area-inset-top));
    display: flex;
}

.avatarText {
    margin-left: 0.5rem;
    max-width: 8rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.topRight {
    position: absolute;
    right: 1rem;
    top: calc(1rem + env(safe-area-inset-top));
    display: flex;
}

.topRight > * + * {
    margin-left: 0.5rem;
}

.bottom {
    position: absolute;
    left: 1rem;
    right: 1rem;
    bottom: calc(1rem + env(safe-area-inset-bottom));
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 0.5rem;
    z-index: 2;
}

.chatArea {
    position: absolute;
    left: 1rem;
    right: 1rem;
    bottom: calc(5.5rem + env(safe-area-inset-bottom));
    display: grid;
    gap: 0.25rem;
    max-height: 9rem;
    overflow-y: auto;
}

.chatArea::-webkit-scrollbar {
    display: none;
}

.chatArea {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.sendButton {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
}

.sendButton > * {
    background: var(--red);
    fill: var(--white);
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.25rem;
}

.reConnect {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 5.5rem;
    left: 1rem;
    right: 1rem;
}

.hideAll {
    animation: hide 0.1s forwards;
}

.notHideAll {
    animation: notHide 0.1s forwards;
}

@keyframes hide {
    0% {
        opacity: 1;
        margin-left: 0;
    }

    99% {
        margin-left: 0;
    }

    100% {
        margin-left: -9999px;
        opacity: 0;
    }
}

@keyframes notHide {
    100% {
        opacity: 1;
        margin-left: 0;
    }

    1% {
        margin-left: 0;
    }

    0% {
        margin-left: -9999px;
        opacity: 0;
    }
}

@media (--lg) {
    .chatArea {
        max-height: calc(100% - 12rem);
    }

    .wrapper {
        display: flex;
        justify-content: space-around;
        padding: 0 1rem;
    }

    .wrapper.fullScreen {
        padding: 0
    }

    .video {
        padding: 1rem 0;
    }

    .video.fullScreen {
        padding: 0
    }

    .video > * {
        border-radius: 1rem;
    }

    .video.fullScreen > * {
        border-radius: 0;
    }

    .chat {
        position: relative;
    }

    .chat.fullScreen {
        position: absolute;
    }
}
