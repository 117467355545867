.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.25rem 1.5rem;
    background: var(--white);
    border-radius: 0.5rem;
    margin-top: 0.5rem;
}

.title {
    text-align: center;
}
