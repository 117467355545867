.inputs {
    margin-top: 2rem;
}

.inputs > * + * {
    margin-top: 0.5rem;
}

.inputTrailing {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
}

.dateInput {
    position: relative;
    width: 100%;
    height: 3.5rem;
    display: flex;
    background-color: var(--white);
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    overflow: hidden;

    flex: 1;
    padding-left: 1rem;
    color: var(--dark);
    font-weight: 400;

    outline: 0 !important;
    appearance: none;
}

.dateInput::placeholder {
    opacity: 1;
    color: var(--gray-5);
    font-weight: 500;
    font-size: 1rem;
    font-family: var(--base-font-family) !important;
}

.list {
    margin-top: 0.5rem;
}

.list > * {
    color: var(--dark)
}

.itemWrapper {
    margin-top: 0.5rem;
    height: 10.75rem;
    overflow: auto;
}

.itemWrapper::-webkit-scrollbar {
    width: 0.5rem;
    background-color: var(--gray-6);
    border-radius: 1rem;
}

.itemWrapper::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: var(--gray-5);
}

.actions {
    padding-top: 1.5rem;
    margin-top: auto;
}

.calendar {
    margin: 0 auto;
    border-radius: 1rem;
    overflow: hidden;
}

.dateInput {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 3.5rem;
    display: flex;
    background-color: var(--white);
    border: 1px solid var(--border-color);
    border-radius: 0.5rem;
    overflow: hidden;
    align-items: center;
}

.dateInputText {
    width: 100%;
    flex: 1;
    color: var(--gray-5);
    font-weight: 500;
    font-size: 1rem;
}

.dateInputText.active {
    color: var(--dark);
    font-weight: 500;
    font-size: 0.875rem;
}

