.heart {
    position: absolute;
    right: 0;
    top: 0;
    animation: example 1.5s;
    z-index: 0;
}

.heart > svg {
    width: 2.5rem;
    height: 2.5rem;
}

@keyframes example {
    0% {
        top: 0
    }

    100% {
        opacity: 0;
        top: -200px
    }
}
