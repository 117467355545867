.actions {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 1rem;
}

.actions > * + * {
    margin-left: 0.5rem !important;
}
