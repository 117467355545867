.wrapper {
    position: relative;
}

.button {
    position: relative;
    width: 100%;
    height: 3.5rem;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    color: var(--dark);
    background: var(--gray-1);
    border: 0;
    outline: 0;
    border-radius: 0.5rem;
    cursor: pointer;
}

.button:hover {
    background: var(--gray-6);
}

.button.button-open {
    background: var(--gray-6);
}

.button.button-disabled {
    opacity: 0.5
}

.button.button-disabled:hover {
    background: var(--gray-1);
}

.button-label {
    flex: 1;
    text-align: left;
}

.button-indicator svg {
    margin-top: 3px;
    fill: currentColor;
}

.options-wrapper {
    position: absolute;
    width: 100%;
    margin-top: 0.25rem;
    background: var(--gray-1);
    border-radius: 0.5rem;
    box-shadow: var(--shadow-2);
    z-index: 1;
}

.options {
    max-height: 22rem;
    padding: 0.5rem 0;
    margin: 0;
    overflow: auto;
    list-style: none;
    outline: 0;
    border-radius: 0.5rem;
    cursor: pointer;
}

.options > * + * {
    border-top: 1px solid var(--black-transparent);
}

.option {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    color: var(--dark);
    font-weight: 500;
    outline: 0;
}

.option.active {
    background-color: var(--gray-6);
}

.option svg {
    width: 1rem;
    height: 1rem;
    visibility: hidden;
}

.selected svg {
    fill: var(--dark);
    visibility: visible;
}

.option-label {
    flex: 1;
}
