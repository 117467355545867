@import '../../../styles/viewports.css';

.home {
    max-width: 89.75rem;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 5rem;
}

.title {
    margin: 0 1rem;
}

.header {
    width: 100%;
}

.header > .card-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    margin: 0 1rem;
}

.divider {
    flex: 0 0 1rem;
}

.listLoader {
    padding: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (--xs) {
    .header > .card-wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (--md) {
    .header {
        padding-bottom: 0;
    }

    .home {
        display: grid;
        grid-template-columns: 13rem 1fr;
        align-items: flex-start;
        justify-content: center;
        padding-top: 1.5rem;
        gap: 1rem;
    }

    .header > .card-wrapper {
        grid-template-columns: repeat(2, 1fr);
        margin: 0;
    }
}

@media (--lg) {
    .home {
        padding-bottom: 2rem;
    }

    .header > .card-wrapper {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (--xl) {
    .header > .card-wrapper {
        grid-template-columns: repeat(4, 1fr);
    }
}
