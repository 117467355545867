.text {
}

.text.size-2xs {
    font-size: 0.625rem;
}

.text.size-xs {
    font-size: 0.75rem;
}

.text.size-sm {
    font-size: 0.875rem;
}

.text.size-base {
    font-size: 1rem;
}

.text.size-lg {
    font-size: 1.125rem;
}

.text.size-xl {
    font-size: 1.25rem;
}

.text.size-2xl {
    font-size: 1.5rem;
}

.text.size-3xl {
    font-size: 1.75rem;
}

.text.size-4xl {
    font-size: 2rem;
}

.text.size-5xl {
    font-size: 3rem;
}

.text.type-default {
    color: var(--dark);
}

.text.type-dimmed {
    color: var(--gray-2);
}

.text.type-dimmed-light {
    color: var(--gray-4);
}

.text.type-soft-blue {
    color: var(--gray-5);
}

.text.type-warning {
    color: var(--yellow);
}

.text.type-danger {
    color: var(--pink);
}
.text.type-danger-2 {
    color: #E3352A;
}

.text.type-white {
    color: #fff;
}

.text.weight-default {
    font-weight: 400;
}

.text.weight-medium {
    font-weight: 500;
}

.text.weight-semibold {
    font-weight: 600;
}

.text.weight-bold {
    font-weight: 700;
}

.text.decoration-underline {
    text-decoration: underline;
}

.text.clickable {
    cursor: pointer;
}

.text.clickable:hover {
    opacity: 0.7;
    cursor: pointer;
}
