@import '../../../styles/viewports.css';

.home {
    max-width: 89.75rem;
    width: 100%;
    margin: 0 auto 3rem auto;
}

.header {
    display: flex;
    justify-content: space-between;
    padding-top: 2.875rem;
}

.texts {
    max-width: 31.5rem;
}

.subtext {
    margin-top: 0.8125rem;
}

.currentPlanCard {
    margin-top: 0.4375rem;
}

.cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    justify-content: space-between;
    margin-top: 2rem;
}

.swiper {
    margin: -4.5625rem auto 0;
    z-index: 1;
}

.mobileButton {
    margin: 0.5rem 1rem 5rem;
}

.restoreButton {
    margin-top: 1.5rem
}

.privacyButton {
    margin-top: 1.5rem
}

.imageContainer {
    height: 23.5625rem;
    padding-top: 1.375rem;
    text-align: center;
    position: relative;
}

.imageContainer > span {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;
}

.imageContainer > *:nth-child(2) {
    position: absolute;
    top: 1.375rem;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
}

.imageContainer > *:nth-child(3) {
    position: absolute;
    top: 11.125rem;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
}


.imageContainer > *:nth-child(4) {
    position: absolute;
    top: 13rem;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    margin: 0 2.25rem;
}

.imageContainer > *:nth-child(5) {
    position: absolute;
    top: 17.25rem;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    margin: 0 2.25rem;
}

@media (--lg) {
    .cards {
        grid-template-columns: repeat(3, 1fr);
    }

    .home {
        padding: 0 1rem;
    }
}

@media (--xl) {
    .cards {
        grid-template-columns: repeat(4, 1fr);
    }
}
