@import '../../../styles/viewports.css';

.web-container {
    max-width: 89.75rem;
    width: 100%;
    margin: 0 auto;
    padding: 0 1rem;
}

.web-wrapper {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 21.5rem 1fr;
    gap: 1rem;
}

.web-activities {
    display: grid;
    grid-template-columns: 1fr 21.5rem;
    gap: 1rem;
}

.web-activities > *:nth-child(1) > * {
    margin-bottom: 1rem;
}

.profile-card > span  {
    overflow: hidden;
    width: 100%;
}

.modalWrapper {
    padding: 1rem
}

.contentWrapper {
    margin-bottom: 1rem;
    max-height: calc(100vh - 16rem);
    overflow: auto;
    padding-right: 1.5rem;
    white-space: pre-wrap;
}

.contentWrapper::-webkit-scrollbar {
    width: 0.5rem;
    background-color: var(--gray-6);
    border-radius: 1rem;
}

.contentWrapper::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: var(--gray-5);
}

.modal > button {
    margin: 0 auto
}

.modal {
    padding: 1.5rem;
}

.about-card-wrapper > *:not(:last-child) {
    margin-bottom: 2rem;
}

.about-card {
    display: grid;
    grid-template-columns: 1.5rem auto 1fr;
    gap: 0.5rem;
    align-items: center;
    fill: var(--red);
}

.about-card > *:nth-child(3) {
    text-align: right;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 0;
}

.mobile-area {
    position: relative;
    margin-top: -2.5rem;
    padding: 0 1rem 5rem;
}

.mobile-user-card {
    background: var(--white);
    padding: 0 1rem;
    height: 5rem;
    border-radius: 1rem 1rem 0 0;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto;
}

.tabs {
    height: 2rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: var(--gray-8);
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    margin-bottom: 1rem;
}

.tab {
    width: 100%;
    height: 100%;
    font-size: 0.75rem;
    color: var(--dark);
    display: flex;
    justify-content: center;
    align-items: center;
}

.tab.activeTab {
    background: var(--white);
    border-radius: 0.25rem;
}

.badge {
    background: var(--red-transparent);
    color: var(--red);
    fill: var(--red);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 0.5rem;
    height: 2rem;
    font-size: 0.75rem;
    padding: 0 0.5rem;
    font-weight: 600;
}

.badge > svg {
    width: 0.75rem;
    height: 0.75rem;
}

.popover {
    position: relative;
    width: 100%;
}

.popover > * {
    width: 100%;
}

.calendar {
    margin: 0 auto;
    border-radius: 1rem;
    overflow: hidden;
}

.update-my-profile {
    margin-top: 1rem;
    width: 100%;
    padding: 1rem;
    display: grid;
    grid-template-columns: auto;
    gap: 1rem;
    margin-bottom: 5rem;
}

.inputTrailing {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
}

.center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.descriptionCard {
    margin-bottom: 1rem;
}

.reportButton {
    margin-top: 1rem;
}

.dateInput {
    cursor: pointer;
}

@media (--lg) {
    .update-my-profile {
        max-width: 23.5rem;
        margin-bottom: 0;
    }
}
