@import "../../../styles/variables.css";

.dayBlock {
    padding: 1.5rem .3125rem;
    text-align: center;
    cursor: pointer;
    font-family: var(--base-font-family);
    font-weight: 500;
    font-size: .875rem;
    width: 3.125rem;

    display: flex;
    flex-direction: column;
}

.dayBlockDesktop {
    padding: 1rem 0;
    font-size: 1rem;
    flex-direction: row;
    border-top: 1px solid var(--gray-9);
    width: auto;
    vertical-align: center;
    align-items: center;
}

.dayBlockDesktop:last-of-type {
    border-top: none;
}

.dayName {
    padding-bottom: .875rem;
}

.dayBlockDesktop .dayName {
    padding-bottom: 0;
}

.dayNumber {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    border-radius: .5rem;
    width: 2.5rem;
    height: 2.5rem;

    font-size: 1.25rem;

    color: var(--white);
}

.dayBlockDesktop .dayNumber {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
    margin-right: 1rem;
}

.past {
    background: var(--red-transparent);
    color: var(--red);
}

.today {
    background: var(--red);
    color: var(--white)
}

.future {
    border: .0625rem solid var(--gray-10);
    background: var(--white);
    color: var(--dark);
}

.daySelected .dayNumber {
    border: 1px solid var(--red)
}



