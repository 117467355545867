.wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    background: var(--white);
    border-radius: 0.5rem;
}

.reset {
    outline: none;
}

.wrapper.active {
    border-radius: 0.5rem 0.5rem 0 0;
}

.body {
    flex: 1;
    padding: 0 1rem;
    line-height: 1.4;
}

.icon {
    width: 1.5rem;
    height: 1.5rem;
    fill: var(--dark-blue);
}

.loaderWrapper {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: var(--z-index-5);
}

.videoWrapper,
.videoWrapper iframe {
    width: 100%;
    height: 100%;
}

.closeButton {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: var(--z-index-5);
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
