@import '../../../styles/viewports.css';

.wrapper {
    max-width: 45.5rem;
    width: 100%;
    margin: 0 auto 0 auto;
    padding: 1rem;
}

.wrapper > div {
    padding-bottom: 1.5rem;
}

@media (--lg) {
    .wrapper {
        margin: 0 auto;
    }

    .wrapper > div {
        padding: 3.5rem 0;
    }
}
