.button-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 1rem;
}

.button {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0;
    padding: 0;
    cursor: pointer;
    color: var(--dark);
    background: none;
    border: 0;
    outline: 0 !important;
}

.button svg {
    fill: currentColor;
}
