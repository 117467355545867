.wrapper {
    max-width: 45.5rem;
    width: 100%;
    margin: 0 auto;
    padding: 0 1rem;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto 0;
    justify-content: center;
    align-items: center;
}

.icon{
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon > svg {
    width: 10rem;
    height: 10rem;
}

.icon.success {
    fill: var(--green)
}

.icon.error {
    fill: var(--red)
}

.texts{
    text-align: center;
    margin: 1rem 0;
}
