@import '../../../styles/viewports.css';

.performance-card {
    display: grid;
    align-items: center;
    grid-template-columns: 3.5rem 1fr 2rem;
    padding: 0.7rem 1rem 1.75rem;
    gap: 1rem;
    background: var(--white);
    border-radius: 0.875rem;
    height: 5rem;
    width: 100%;
    fill: var(--dark);
    cursor: pointer;
}

.performance-card.selected {
    border: 1px solid var(--red);
    border-radius: 0.875rem 0.875rem 0 0;
}

.performance-card > * {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.icon {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 1.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: var(--red-transparent);
}

.icon > svg {
    fill: var(--red);
    width: 1.5rem;
    height: 1.5rem;
}

.icon.selected {
    background: var(--red);
}

.icon.selected > svg {
    fill: var(--white);
}

.dropdown {
    overflow: hidden;
}

.dropdown > * {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3rem;
    padding: 0.75rem;
    border-top: 1px solid var(--border-color);
    background: var(--white);
}

@media (--lg) {
    .performance-card.selected {
        border-radius: 0.875rem;
        border: 1px solid var(--red);
    }

    .performance-card {
        max-width: 21.75rem;
    }
}
